import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/web-application-security",
  "date": "2017-05-30",
  "title": "WEB APPLICATION SECURITY",
  "author": "admin",
  "tags": ["development", "security"],
  "featuredImage": "feature.jpg",
  "excerpt": "Cyber security has become a necessity for any web developer building client-facing web applications.  We are looking into few options for securing our app."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why cyber-security?`}</h2>
    <p>{`Cyber security has become a necessity for any web developer building client-facing web applications.`}</p>
    <p>{`We are looking into following options for securing our app:`}</p>
    <ul>
      <li parentName="ul">{`Disable Caching Confidential Resources`}</li>
      <li parentName="ul">{`Enable XSS Filtering`}</li>
      <li parentName="ul">{`Controlling Framing`}</li>
      <li parentName="ul">{`Whitelisting Sources`}</li>
    </ul>
    <p>{`HTTP headers are an area we can address for starters.`}</p>
    <h2>{`What are HTTP Headers?`}</h2>
    <p><inlineCode parentName="p">{`HTTP request`}</inlineCode>{` and `}<inlineCode parentName="p">{`HTTP response`}</inlineCode>{` messages consist of clear text fields in the header. These are called HTTP Headers.`}</p>
    <p>{`These headers are used by the HTTP client and server to:`}</p>
    <ul>
      <li parentName="ul">{`Send & receive metadata about the connection to be established`}</li>
      <li parentName="ul">{`The resources being requested`}</li>
      <li parentName="ul">{`And the returned resource itself`}</li>
    </ul>
    <h2>{`Disable Caching Confidential Resources`}</h2>
    <p>{`Always caching HTTP `}<inlineCode parentName="p">{`GET`}</inlineCode>{`*  requests, enables HTTP to enhance performance, and reduce network traffic.`}</p>
    <p>{`But this can also expose end users to be vulnerable for personal information theft.`}</p>
    <p>{`There are three headers you can send to teh client to make sure the cache will be disabled:`}</p>
    <ul>
      <li parentName="ul">{`CacheControl`}</li>
      <li parentName="ul">{`Expires`}</li>
      <li parentName="ul">{`Pragma`}</li>
    </ul>
    <h3>{`Cache Control`}</h3>
    <p>{`This header can be used to instruct clients and intermediate proxies on how to respond to caching.`}</p>
    <ul>
      <li parentName="ul">{`no-cache – Do not to use a previously cached response`}</li>
      <li parentName="ul">{`no-store – Do not store the response`}</li>
      <li parentName="ul">{`must-revalidate – if the response is somehow cached, the cache must be revalidated on the origin server`}</li>
    </ul>
    <h3>{`Pragma: no-cache`}</h3>
    <p>{`Some HTTP clients like intermediary proxies, still might not fully support HTTP 1.1 and may have trouble handling the Cache-Control header mentioned above.`}</p>
    <p>{`For backwards-compatibility with HTTP 1.0, you will want to include this header as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`Pragma: no-cache
`}</code></pre>
    <Message type="warn" title="Perfomance" content="Disabling cache will slow down the performance so make sure to disable caching only for confidential resources." mdxType="Message" />
    <p>{`You can write your header with above considerations as shown below:`}</p>
    <p>{`Rails 4:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`response.headers['Cache-Control'] = 'no-cache, no-store, max-age=0, must-revalidate'
response.headers['Expires'] = '-1';
response.headers['Pragma'] = 'no-cache'
`}</code></pre>
    <p>{`Node.js:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`function requestHandler(req, res) {
    res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate');
  res.setHeader('Expires', '-1');
    res.setHeader('Pragma', 'no-cache');
}
`}</code></pre>
    <h2>{`Enable XSS Filtering`}</h2>
    <p>{`Cross-site scripting attack or `}<inlineCode parentName="p">{`reflected XSS`}</inlineCode>{`, is a malicious attack on a web application by injecting JavaScript code into an HTTP request and accessing confidential information like session cookies.`}</p>
    <p>{`Most browsers today, employ a XSS filters to mitigate cross-site scripting attacks but may fail to detect a real XSS attack.`}</p>
    <p>{`But fortunately a web application can override this configuration via an `}<inlineCode parentName="p">{`X-XSS-Protection`}</inlineCode>{` header.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function requestHandler(req, res) {
    res.setHeader('X-XSS-Protection','1;mode=block');
}
`}</code></pre>
    <p><inlineCode parentName="p">{`1`}</inlineCode>{` or `}<inlineCode parentName="p">{`0`}</inlineCode>{` enables or disables the filter.`}</p>
    <p>{`mode=block will block the page from rendering when an attack is detected.`}</p>
    <h2>{`HTTPS`}</h2>
    <p>{`An insecure connection exposes the user to multitude of attacks, which could easily lead to cookie theft or even worse. For an example, A malicious attacker can easily spoof network frames within a public Wi-Fi network and to obtain session cookies of users who are not using HTTPS.`}</p>
    <Message type="info" title="Man-in-the-Middle (MitM) attacks" content="A downgrade attack, which an attempt to force the connection to be downgraded to an insecure connection, thus exposing the user to MitM attacks." mdxType="Message" />
    <p>{`In order to enforce the usage of `}<inlineCode parentName="p">{`HTTPS, we can use the HTTP Strict Transport Security`}</inlineCode>{` (HSTS) header. Put simply, HSTS makes sure all communications with the origin host are using HTTPS.`}</p>
    <p>{`HSTS directives can be configured like the following:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`includeSubDomains`}</em>{` – HSTS will be applied to all sub domains`}</li>
      <li parentName="ul"><em parentName="li">{`max-age=`}</em>{` – browser will cache this header for a given time`}</li>
      <li parentName="ul"><em parentName="li">{`preload`}</em>{` – forces browsers to always load your web app securely by hardcoding a list of HSTS preload-enabled domains into the browser’s code`}</li>
    </ul>
    <p>{`If you are using preload directives, you will have to make sure that your website can only be using HTTPS.`}</p>
    <h2>{`Avoiding Content-Type Sniffing(MIME Sniffing)`}</h2>
    <p>{`This feature enables the browser to detect the type of a resource provided as part of an HTTP response by sniffing the actual resource bits, regardless of the resource type declared through the Content-Type response header. While this feature is indeed useful in some cases, it introduces a vulnerability and an attack vector known as a MIME confusion attack.`}</p>
    <p>{`We can use `}<inlineCode parentName="p">{`X-Content-Type-Options`}</inlineCode>{` header to address this issue.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`X-Content-Type-Options: nosniff
`}</code></pre>
    <h2>{`Controlling Framing`}</h2>
    <p>{`Using iframes in your website can makes clickjacking easier.`}</p>
    <Message type="info" title="Clickjacking" content="An attack that tricks the user into clicking something different than what they think they’re clicking." mdxType="Message" />
    <p>{`By using `}<inlineCode parentName="p">{`X-Frame-Options`}</inlineCode>{` you can block this attack by restricting your web app from being framed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`X-Frame-Options: SAMEORIGIN
`}</code></pre>
    <h2>{`Whitelisting Sources`}</h2>
    <p>{`Content Security Policy (CSP) enables developers to whitelist trusted sources and operations.`}</p>
    <Message type="info" title="Content Security Policy (CSP)" content="CSP is a W3C specification that defines a powerful browser-based security mechanism, enabling granular control over resource-loading and script execution in a web app." mdxType="Message" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      